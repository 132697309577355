<template>
  <tr class="table-row" :class="modifyClasses">
    <slot />
  </tr>
</template>

<script>
export default {
  name: 'TableRow',
  props: {
    highlight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modifyClasses() {
      return [
        this.highlight
          ? 'table-row--highlight'
          : 'table-row--default',
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.table-row--default {
  &:nth-child(odd) {
    border-color: #100f0f;
    background: #100f0f;
  }
  &:nth-child(even) {
    border-color: #161516;
    background: #161516;
  }
}
.table-row--highlight {
  border-color: #204c80;
  background: #204c80;
}
</style>
