<template>
  <component :is="tag" class="table-data" :class="modifyClasses">
    <slot />
  </component>
</template>

<script>
export default {
  name: 'TableData',
  props: {
    heading: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: Boolean,
      default: true,
    },
    textAlign: {
      type: String,
      default: 'center',
    },
    whiteSpace: {
      type: String,
      default: 'nowrap',
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tag() {
      return this.heading ? 'th' : 'td';
    },
    modifyClasses() {
      return [
        this.heading ? 'table-data--heading' : 'table-data--value',
        this.padding ? 'table-data--padding' : '',
        this.small ? 'table-data--small' : '',
        `table-data--${this.textAlign}`,
        `table-data--${this.whiteSpace}`,
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.table-data {
  box-sizing: border-box;
  background: inherit;
  font-weight: 400;
  border: 2px solid;
}
.table-data--value {
  color: #f5f5f7;
  font-size: 12px;
  line-height: 16px;
  border-color: inherit;

  @include min-tablet() {
    font-size: 14px;
    line-height: 18px;
  }

  &.table-data--padding {
    padding: 12px 8px;
    @include min-tablet() {
      padding: 20px;
    }
  }
}
.table-data--heading {
  font-size: 12px;
  line-height: 16px;
  background: #2d2d30;
  border-color: #2d2d30;
  color: #979797;

  &.table-data--padding {
    padding: 12px 8px;
    @include min-tablet() {
      padding: 12px 20px;
    }
  }
}
.table-data--normal {
  white-space: normal;
}
.table-data--nowrap {
  white-space: nowrap;
}
.table-data--right {
  text-align: right;
}
.table-data--center {
  text-align: center;
}
.table-data--left {
  text-align: left;
}
.table-data--small {
  width: 70px;
}
</style>
