<template>
  <div class="table-wrapper">
    <table class="table" :class="modifyClasses">
      <thead class="table__header">
        <slot name="thead" />
      </thead>
      <tbody class="table__body">
        <slot name="tbody" />
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'Table',
  props: {
    sticky: {
      type: Boolean,
      default: false,
    },
    minWidth: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    modifyClasses() {
      return {
        'table--sticky': this.sticky,
        'table--min-width': this.minWidth,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.table-wrapper {
  width: 100%;
  overflow: auto;
  border-radius: 6px;
}

.table {
  width: 100%;
  border-spacing: 0;
  border-collapse: initial; // убрать когда почистим все глобальные css
}

.table--min-width {
  @include min-tablet() {
    min-width: 700px;
  }
}

.table__header::v-deep th:first-child,
.table__body::v-deep td:first-child,
.table__header::v-deep th:nth-child(2),
.table__body::v-deep td:nth-child(2) {
  .table--sticky & {
    position: sticky;
    top: 0;
  }
}

.table__header::v-deep th:first-child,
.table__body::v-deep td:first-child {
  .table--sticky & {
    left: 0;
    max-width: 60px;
    width: 60px;
    min-width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    @include min-tablet() {
      max-width: 80px;
      width: 80px;
      min-width: 80px;
    }
  }
}

.table__header::v-deep th:nth-child(2),
.table__body::v-deep td:nth-child(2) {
  .table--sticky & {
    left: 60px;
    @include min-tablet() {
      left: 80px;
    }
  }
}
</style>
